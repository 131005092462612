import ApplicationController from "../../../../../javascript/controllers/application_controller";
import Cleave from "cleave.js";

export default class extends ApplicationController {
  static targets = ["moneyInput"]
  static values = {
    decimalScale: Number,
    numeralScale: Number,
    inputPrefix: String
  }

  connect() {
    new Cleave(this.moneyInputTarget, {
      prefix: `${this.inputPrefixValue}`,
      delimiter: ',',
      numeral: true,
      numeralThousandsGroupStyle: "thousand",
      swapHiddenInput: true,
      numeralDecimalScale: this.decimalScaleValue,
      numeralIntegerScale: this.numeralScaleValue
    });
    //! Uncomment if you need to generate HTML ID selectors for the Cleave inputs
    // Generate IDs    
    const hiddenInput = this.element.querySelector("input[type='hidden']");
    const storeId = hiddenInput.id 
    this.element.querySelector("input[type='text']").id = storeId;
    hiddenInput.id = `${storeId}-hidden`;
  }
}