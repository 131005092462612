import '@hotwired/turbo-rails'

// Import all channels.
const channels = import.meta.globEager('../**/*_channel.js')

import StimulusReflex from "stimulus_reflex";
import consumer from "../channels/consumer";
import controller from "../controllers/application_controller";
import CableReady from 'cable_ready'
import debounced from "debounced";

import { Application } from "stimulus";
const application = Application.start()
// Configure Stimulus development experience
application.warnings = true
application.debug = true
window.Stimulus = application

debounced.initialize({ input: { wait: 500 } });

application.consumer = consumer
StimulusReflex.initialize(application, { 
  consumer, 
  controller, 
  debug: false,
  isolate: true});
CableReady.initialize({ consumer })

const controllers = import.meta.globEager("../**/*_controller.js");
for (let path in controllers) {
  let module = controllers[path];
  let name = path.match(/\.\/(.+)_controller\.js$/)[1].replace("controllers/", "").replaceAll("/", "--").replaceAll("_", "-");
  application.register(name, module.default);
}

const designControllers = import.meta.globEager("../../components/design/**/*_controller.js");

for (let path in designControllers) {
  let module = designControllers[path];
  let name = path.match(/\.\/(.+)_controller\.js$/)[1].replace("../components/design/", "").replaceAll("/", "--").replaceAll("_", "-");
  application.register(name, module.default);
}

import "trix"
import "@rails/actiontext"