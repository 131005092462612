import ApplicationController from "../../../../javascript/controllers/application_controller";

export default class extends ApplicationController {
  renderMenu(e) {
    this.clickedDropdownItem = e.currentTarget;
    this.dropdownMenu = this.clickedDropdownItem.parentElement;
    this.dropdownMenu.classList.add("vanish", "animate-none");
    this.expander = document.getElementById(`${this.clickedDropdownItem.dataset.menuId}`);
    this.expander.classList.add("is-focused");
  }

  returnMenu() {
    this.expander.classList.remove("is-focused");
    this.dropdownMenu.classList.remove("vanish");
  }
}