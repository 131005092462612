import ApplicationController from "../../../javascript/controllers/application_controller";

export default class extends ApplicationController {
  static values = {
    destroyOnExit: Boolean,
    dismissTime: Number
  }
  connect() {
    super.connect();
    if (super.countToasts() > 1) {
      document.querySelectorAll('.toast-component').forEach((toast, i) => {
        toast.style.marginRight = `${i}rem`;
        toast.style.marginBottom = `${i}rem`;
      });
    }
    if (this.dismissTimeValue) {
      setTimeout(() => {
        this.dismissToast();
      }, this.dismissTimeValue)
    }
  }
  
  dismissToast() {
    super.dismissToast(this.element.id, this.destroyOnExitValue)
  }

  reloadPage() {
    window.location.reload();
  }
}

