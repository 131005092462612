// Rendered from _flash_msg.html.erb partial on connect (right when partial is rendered automatically by controller flash assignment) to show a dismissable flash message.
// Dedicated to Flash Messages, for toast messages visit /toasts
import ApplicationController from "../../../javascript/controllers/application_controller";

export default class extends ApplicationController {
  static values = {type: String};

  connect() {
    this.element.classList.remove("vanish");
    listenDismissClick(this.element);
    if (this.typeValue.match(/timed/g)) this.waitThenDismiss(); //_timed is in the flash key EG: "flash[:notice_timed]"
  }

  closeFlash() {
    closeFlash(this.element)
  }

  waitThenDismiss() {
    setTimeout(() => {
      this.closeFlash();
    }, 5000);
  }
}

function listenDismissClick(flash) {
  document.addEventListener("click", (e) => {
    if (!flash.contains(e.target)) closeFlash(flash);
  })
}

function closeFlash(flash) {
  flash.classList.add("disappear");
  setTimeout(() => {
    flash.remove();
  }, 1000);
}

//? Use and import to call flash messages directly from JS without Reflex dependency.
export function customFlash(type, msg, selector) {
  const flash =  `<div id="flash-component" 
        class="flash-component ${type}" 
        data-controller="flash--flash"
        data-flash--flash-type-value="${type}"
  >
    <div class="flash-container">
      <div class="flash-wrapper">
        <div class="flash-close-btn-container">
          <button class="flash-close-btn" data-action="click->flash--flash#closeFlash" type="button" title="Close popup" aria-label="Close this popup">
            &times;
          </button>
        </div>
        <div class="flash-body-container" tabindex="-1" role="alertdialog" aria-live="assertive">
          <div class="flash-icon-container">
            ${returnIcon(type)}
          </div>
          <div class="flash-message-container">
            ${msg}
          </div>
        </div>
      </div>
    </div>
  </div>`
  document.querySelector(`${selector}`).insertAdjacentHTML("afterend", flash);
}

function returnIcon(type) {
  if (type == "notice") return '<svg class="notice-icon flash-icon" viewBox="0 0 512 512"><path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm155.31 195.31l-184 184a16 16 0 0 1-22.62 0l-104-104a16 16 0 0 1 0-22.62l22.62-22.63a16 16 0 0 1 22.63 0L216 308.12l150.06-150.06a16 16 0 0 1 22.63 0l22.62 22.63a16 16 0 0 1 0 22.62z" class="fa-secondary"/><path d="M227.31 387.31a16 16 0 0 1-22.62 0l-104-104a16 16 0 0 1 0-22.62l22.62-22.63a16 16 0 0 1 22.63 0L216 308.12l150.06-150.06a16 16 0 0 1 22.63 0l22.62 22.63a16 16 0 0 1 0 22.62l-184 184z" class="fa-primary"/></svg>';
  if (type == "alert") return '<svg class="alert-icon flash-icon" viewBox="0 0 512 512"><path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1a12 12 0 0 1 0 17L338 377.6a12 12 0 0 1-17 0L256 312l-65.1 65.6a12 12 0 0 1-17 0L134.4 338a12 12 0 0 1 0-17l65.6-65-65.6-65.1a12 12 0 0 1 0-17l39.6-39.6a12 12 0 0 1 17 0l65 65.7 65.1-65.6a12 12 0 0 1 17 0l39.6 39.6a12 12 0 0 1 0 17L312 256z" class="fa-secondary"/><path d="M377.6 321.1a12 12 0 0 1 0 17L338 377.6a12 12 0 0 1-17 0L256 312l-65.1 65.6a12 12 0 0 1-17 0L134.4 338a12 12 0 0 1 0-17l65.6-65-65.6-65.1a12 12 0 0 1 0-17l39.6-39.6a12 12 0 0 1 17 0l65 65.7 65.1-65.6a12 12 0 0 1 17 0l39.6 39.6a12 12 0 0 1 0 17L312 256z" class="fa-primary"/></svg>'
  return '<svg class="info-icon flash-icon" viewBox="0 0 512 512"><path d="M256 8C119 8 8 119.08 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 376a32 32 0 1 1 32-32 32 32 0 0 1-32 32zm38.24-238.41l-12.8 128A16 16 0 0 1 265.52 288h-19a16 16 0 0 1-15.92-14.41l-12.8-128A16 16 0 0 1 233.68 128h44.64a16 16 0 0 1 15.92 17.59z" class="fa-secondary"/><path d="M278.32 128h-44.64a16 16 0 0 0-15.92 17.59l12.8 128A16 16 0 0 0 246.48 288h19a16 16 0 0 0 15.92-14.41l12.8-128A16 16 0 0 0 278.32 128zM256 320a32 32 0 1 0 32 32 32 32 0 0 0-32-32z" class="fa-primary"/></svg>'
}