import ApplicationController from "../../../javascript/controllers/application_controller";

export default class extends ApplicationController {

  dismiss() {
    this.element.classList.add("animate-fade-out");
  }

  removeAlert() {
    this.element.remove();
  }
}