import ApplicationController from "../../../../javascript/controllers/application_controller";

export default class extends ApplicationController {
  showPasswordField() {
    this.passwordInput = document.querySelector("input[type='password']");
    this.passwordInput.type = 'text';
    this.element.style.pointerEvents = 'none';
    setTimeout(() =>{
      this.resetPasswordField();
    }, 5000)
  }

  resetPasswordField() {
    this.passwordInput.type = "password";
    this.element.style.pointerEvents = 'auto';
  }
}