import ApplicationController from "../../../javascript/controllers/application_controller";
export default class extends ApplicationController {
// Resource target refers to the element loading in. Loader target is the spin or skeleton loader that will be removed once resource is loaded in.
// Shouldn't necessarily need to use resourceTarget, since the 'load' action will execute #successfulLoad, you pass in to that resource data-action="load->loaders--load#successfulLoad"
static targets = [ "resource", "loader" ]

// works off resources' data-action='load->loaders--load#removeLoader'
  removeLoader() {
    if (this.hasLoaderTarget) this.loaderTarget.remove()
  }
}