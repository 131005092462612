import ApplicationController from "../../../../javascript/controllers/application_controller";
import { closeModal } from "../modal_controller";

export default class extends ApplicationController {
  static targets = [
    "confirmBtn"
  ];

  static values = {
    selectorId: String,
    isModalTrigger: Boolean,
    icon: String,
    message: String,
    modalTitle: String
  };

  initialize() {
    //? This code executes when the modal is rendered via render_confirm_modal reflex and this.element is modal itself 
    if (!this.hasIsModalTriggerValue) {
      const btnContainer = document.querySelector(`${this.element.dataset['modal-ModalSelectorValue']}-btn`);
      const btn = btnContainer?.querySelector("button") || btnContainer?.querySelector("a")

      super.removeVanish(btnContainer);
      this.element.querySelector("footer .confirm-modal-button-container").appendChild(btnContainer);
      //? Make sure confirm modal closes when action is clicked
      btn?.addEventListener("click", () => closeModal(this.element));
    }
  }

  renderModal() {
    this.stimulate("ApplicationReflex#render_confirm_modal", this.selectorIdValue, this.iconValue, this.messageValue, this.modalTitleValue)
  }
}