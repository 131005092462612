import { Controller } from "stimulus";
import StimulusReflex from "stimulus_reflex";

/* This is your application's ApplicationController.
 * All StimulusReflex controllers should inherit from this class.
 *
 * Example:
 *
 *   import ApplicationController from './application_controller'
 *
 *   export default class extends ApplicationController { ... }
 *
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends Controller {
  connect() {
    StimulusReflex.register(this);
  }

  /* Application wide lifecycle methods.
   * Use these methods to handle lifecycle concerns for the entire application.
   * Using the lifecycle is optional, so feel free to delete these stubs if you don't need them.
   *
   * Arguments:
   *
   *   element - the element that triggered the reflex
   *             may be different than the Stimulus controller's this.element
   *
   *   reflex - the name of the reflex e.g. "ExampleReflex#demo"
   *
   *   error - error message from the server
   */

  addVanish(element) {
    element.classList.add("vanish");
  }

  removeVanish(element) {
    element.classList.remove("vanish");
  }

  toggleVanish(element) {
    element.classList.toggle("vanish");
  }

  focusFirstEl(parent = "main") {
    const firstFocusable = parent.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])')[0];
    firstFocusable.focus();
  }
  
  unfocusTarget(target) {
    setTimeout(() => {
      target?.blur();
    }, 500);
  }

  // Below - Custom toasts for design/toasts
  // When using this, avoid using connect() in the controller you're calling from and use initialize() instead, since there's a conflict with this connect.
  // If you have to use connect() in the controller, first call super.connect().
  renderToast(selector, message, iconType = "warning", reload_page = false, current_user_id = null, dismissTime = null, hideBtn = false) {
    if (this.alreadyHasToast(selector)) return;
    if (!selector.includes("#") && !selector.includes(".")) selector = `#${selector}`; // Add prefix for # if passed without it or a custom class selector
    this.stimulate("ApplicationReflex#render_toast", selector, message, this.countToasts(), iconType, reload_page, current_user_id, dismissTime, hideBtn);  
  }
  
  alreadyHasToast(id) {
    const toast = document.getElementById(`toast-component-${id}`);
    return toast;
  }

  renderFlash(msg, name) {
    this.stimulate("ApplicationReflex#render_cable_flash", msg, name)
  }
  
  // Counts toasts on page as margin to component so they stack
  countToasts() {
    return document.querySelectorAll(".toast-component").length;
  }

  dismissToast(id) {
    if (!id.includes('toast-component')) id = `toast-component-${id}`; // Will fix ID if what's passed doesn't have the 'toast-component' prefix.

    const toast = document.getElementById(id);
    
    if (toast) {
      toast.classList.add('dismiss');
      setTimeout(() => {
        toast.remove();
      }, 550);
    }
  }
  beforeReflex(element, reflex) {
    // document.body.classList.add('wait')
  }

  reflexSuccess(element, reflex, error) {
    // show success message etc...
  }

  reflexError(element, reflex, error) {
    // show error message etc...
    this.renderFlash(
      "There was some sort of error. Please refresh (F5) the page.", "alert"
    );
  }

  afterReflex(element, reflex) {
    // document.body.classList.remove('wait')
  }
}
