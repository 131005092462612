import { Controller } from "stimulus";

export default class extends Controller{
    static targets = ["tickerBanner"];

    stopMarquee(){
        this.tickerBannerTarget.stop();
    }
    startMarquee(){
        this.tickerBannerTarget.start();
    }
}