import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  export() {
    const form = document.querySelector("#report-filter-form");
    const formData = new FormData(form);

    const dataObj = {};
    formData.forEach((value, key) => {
      dataObj[key] = value;
    });

    const paramsString = this.objectToQueryString(dataObj);
    window.location.href = '/export_report?' + paramsString;
  }

  objectToQueryString(obj) {
    return Object.keys(obj)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
      .join('&');
  }
}