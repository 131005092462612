import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  connect() {
    this.element.querySelectorAll('a').forEach(link => {
      if (link.host !== window.location.host) {
        link.target = "_blank"
      }
    })
  }
}