import ApplicationController from "../../../javascript/controllers/application_controller";
export default class extends ApplicationController {

  switchTab(e) {
    this.removeActiveClasses(this.element);
    this.addIsActive(e.target);
  }

  addIsActive(clickedTab) {
    clickedTab.dataset['isActive'] = "true"; 
    clickedTab.setAttribute("tabindex", "-1");
    clickedTab.setAttribute("aria-selected", "true");
  }

  removeActiveClasses(tabContainerParent) {
    const activeTab = tabContainerParent.querySelector('[data-is-active="true"]');
    activeTab.dataset["isActive"] = "false";
    activeTab.removeAttribute("tabindex");
    activeTab.setAttribute("aria-selected", "false");
  }
}