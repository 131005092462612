//? Simple controller for toggling a class. Below is an example on front end:
/* 
    <button data-controller="utils--toggle-class"
            data-action="click->utils--toggle-class#toggle"
            data-utils--toggle-class-toggle-class-value="vanish"
            data-utils--toggle-class-element-selector-value=".chat-members-container"
    ></button>
    <div class="chat-members-container">
*/
import ApplicationController from "../../../javascript/controllers/application_controller";

export default class extends ApplicationController {
  static values = { toggleClass: String, elementSelector: String }

  toggle() {
    document.querySelector(`${this.elementSelectorValue}`).classList.toggle(`${this.toggleClassValue}`)
  }
}