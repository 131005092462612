import ApplicationController from "../../../../javascript/controllers/application_controller";

export default class extends ApplicationController {
  static targets = [ 
    "selectInput", 
    "selectDropdown", 
    "visibleSelection", 
    "placeholder", 
    "selectOption", 
    "pillTemplate", 
    "selectedPill",
    "noMatchMsg",
    "searchInput",
    "selectAllBtn"
  ]
  static values = { 
    hasSearch: Boolean, 
    hasSelectAll: Boolean, 
    isOpen: Boolean,
    multiple: Boolean
  }

  initialize() {
    // Render select box pills if selected (like on edit)
    if (this.hasAnySelections()) this.renderExistingSelectPills();
    this.hasSearchValue = this.hasSearchInputTarget;
  }
  
  toggleDropdownOptions(e) {
    if (this.isOpenValue) return this.hide(); 
    this.show();
    this.setCloseListener();
  }

  show() {
    this.selectDropdownTarget.classList.remove("animate-slide-and-fade-out-up-lesser", "vanish");
    this.selectDropdownTarget.classList.add("animate-slide-and-fade-in-down-lesser");
    this.element.parentElement.classList.add("has-focus");
    this.isOpenValue = true;
    if (this.hasSearchValue) this.searchInputTarget.focus();
  }

  hide() {
    this.isOpenValue = false;
    this.selectDropdownTarget.classList.add("vanish");
    this.element.parentElement.classList.remove("has-focus");
  }

  selectAll() {
    this.selectAllBtnTarget.dataset.selected == "true" ? this.deselectAllOptions() : this.selectAllOptions();
  }
  
  selectAllOptions() {
    this.selectOptionTargets.forEach(option => {
      if (option.dataset.selected == "true") return;
      option.dataset.selected = "true";
      this.selectedOption = this.selectInputTarget.querySelector(`option[value="${option.dataset.value}"]`);
      this.selectedOption.selected = true;
      this.renderSelectPill(option.nextElementSibling);
    });
    this.selectAllBtnTarget.dataset.selected = "true";
    this.selectAllBtnTarget.textContent = "Deselect All";
    this.selectAllBtnTarget.title = "Deselect all options below"
    this.removeOrReplacePlaceholder();
  }

  deselectAllOptions() {
    this.selectOptionTargets.forEach(option => {
      if (option.dataset.selected == "false") return;
      option.dataset.selected = "false";
      this.selectedOption = this.selectInputTarget.querySelector(`option[value="${option.dataset.value}"]`);
      this.selectedOption.selected = false;
      this.removeSelectedPill(option.dataset.value, null);
    });
    this.selectAllBtnTarget.dataset.selected = "false";
    this.selectAllBtnTarget.textContent = "Select All";
    this.selectAllBtnTarget.title = "Select all options below"
    super.removeVanish(this.placeholderTarget);
  }

  // Close select dropdown when user clicks out
  setCloseListener() {
    document.addEventListener("click", (e) => {
      if (!this.element.contains(e.target)) {
        this.selectDropdownTarget.classList.remove("animate-slide-and-fade-in-down-lesser");
        this.selectDropdownTarget.classList.add("animate-slide-and-fade-out-up-lesser");
        setTimeout(() => {
          this.hide();
        }, 500);
      }
    });
  }

  // If there's a selection and .has-errors has been added by checkMultiselectsValidity(), removes it.
  removeErrors() {
    if (this.hasAnySelections()) this.element.classList.remove("has-errors");
  }

  // Value inside dropdown clicked
  selectValue(e) {
    this.selection = e.currentTarget;
    const selectedPill = this.selection.nextElementSibling; // Grabs hidden div that is pill so it can be appended to visible select box
    this.clickedOptionValue = this.selection.dataset.value;
    this.selectedOption = this.selectInputTarget.querySelector(`option[value="${this.clickedOptionValue}"]`);
    
    if (this.selection.dataset.selected == "true") {
      this.selection.dataset.selected = "false";
      this.selectedOption.selected = false;
      this.removeSelectedPill(this.clickedOptionValue, null);
    } else {
      this.selection.dataset.selected = "true";
      this.selectedOption.selected = true;
      this.renderSelectPill(selectedPill);
    }

    this.removeErrors();
    this.removeOrReplacePlaceholder();
  }
  
  // pill inside of select box clicked
  removeValue(e) {
    e.stopPropagation();
    const selectedPill = e.currentTarget;
    this.clickedOptionValue = selectedPill.dataset.value;
    this.selectedOption = this.selectInputTarget.querySelector(`option[value="${this.clickedOptionValue}"]`);
    this.selectedOption.selected = false;

    this.selectOptionTargets.forEach(option => { 
      if (option.dataset.value == this.clickedOptionValue) option.dataset.selected = "false";
    })

    this.removeSelectedPill(this.clickedOptionValue, selectedPill)
    this.removeOrReplacePlaceholder();
  }

  removeSelectedPill(value, selectedPill) {
    const pill = selectedPill || this.selectedPillTargets.find(pill => pill.dataset.value == value);
    pill.classList.remove("animate-fade-in");
    pill.classList.add("animate-fade-out");
    setTimeout(() => {
      pill.remove();
    }, 450);
  }

  renderSelectPill(template) {
    this.pill = template.cloneNode(true)
    this.pill.id = this.pill.id.replace(/\-template$/, "")

    this.pill.classList.remove('vanish', 'multiselect-dropdown-pill-template');
    this.pill.classList.add('multiselect-dropdown-pill');
    this.pill.setAttribute('data-forms--multiselect--multiselect-component-target', 'selectedPill');
    this.visibleSelectionTarget.append(this.pill);
  }

  renderExistingSelectPills() {
    this.selectOptionTargets.forEach((option) => {
      if (option.dataset.selected == "true") this.renderSelectPill(option.nextElementSibling);
    })
  }

  removeOrReplacePlaceholder() {
    if (this.hasAnySelections()) return super.addVanish(this.placeholderTarget);
    setTimeout(() => {
      super.removeVanish(this.placeholderTarget);
    }, 450) // Delay so animation can finish
  }

  hasAnySelections() {
    return this.selectInputTarget.selectedOptions.length > 0;
  }

  search(e) {
    const searchValue = e.target.value.toUpperCase();
    let optionValue;
    let hasMatch = false;

    this.selectOptionTargets.forEach((option) => {
      optionValue = option.textContent || option.innerText;
      if (optionValue.toUpperCase().indexOf(searchValue) > -1) {
        option.style.display = "";
        hasMatch = true;
        this.toggleCategories(true);
      } else {
        option.style.display = "none";
      }
    });
    if (searchValue == "") this.toggleCategories(false);
    if (hasMatch) return super.addVanish(this.noMatchMsgTarget);
    super.removeVanish(this.noMatchMsgTarget);
  }
  
  // Hide any category names
  toggleCategories(hide) {
    document?.querySelectorAll(".multiselect-category")?.forEach((categoryText) => {
      if (hide) {
        return super.addVanish(categoryText);
      }
      super.removeVanish(categoryText);
    })

  }
}
// Import this into the form's controller if it has required multiselects
// Generate errors for required selects using multiselect-component
export function checkMultiselectsValidity(showToastIfInvalid = true) {
  const multiselectComponents = document.querySelectorAll('.multiselect-component');
  let showToast = false;
  multiselectComponents.forEach(component => {
    const select = component.querySelector('select');
    if (select.hasAttribute('required') && !select.value) {
      component.classList.add("has-errors");
      if (showToastIfInvalid) showToast = true;
    }
  })
  return showToast;
}
