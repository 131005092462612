import ApplicationController from "../../../../javascript/controllers/application_controller";

export default class extends ApplicationController {
  static targets = [
    "emailInput",
    "loginFormContainer",
    "roleBtnContainer",
    "localUserBtnContainer"
  ];

  showLoginForm() {
    this.roleBtnContainerTarget.classList.add("is-selected");
    this.loginFormContainerTarget.classList.remove("vanish");
    this.localUserBtnContainerTarget.classList.add("is-selected");
    setTimeout(() => {
      this.emailInputTarget.focus();
    }, 349);
  }

}
