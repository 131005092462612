import ApplicationController from "../../../javascript/controllers/application_controller";

export default class extends ApplicationController {
  // ID is MOdals random generated ID in view component. 
  // Selector is the class or ID passed in the open modal button or link that corresponds with the VC, so like 'data-modal-selector-value=".choose-language-modal"'
  static targets = [
    "modal", "modalCard", "modalBackground"
  ];
  static values = {
    selector: String, 
    destroyOnExit: Boolean, 
    listenForEsc: Boolean,
    stopScroll: Boolean,
    exitAnimationDuration: Number,
    closeModalOnActionClick: Boolean
  }
  connect() {
    if (this.hasModalTarget) {
      if (this.modalTarget.classList.contains('is-active')) this.showModal();
    } 
  }
  
  showModal() {
    if (this.stopScrollValue) document.body.setAttribute("modal-open", true);
    document.querySelector("main").classList.add("modal-is-open");
    this.modal = this.hasModalTarget ? this.modalTarget : document.getElementById(`modal-component-${this.selectorValue}`);
    this.modal.classList.add('is-active') 
    this.focusModal();
    //? Set event listener to close modal on successful form submit
    if (this.closeModalOnActionClickValue) this.modal?.querySelector("footer button[type='submit']").addEventListener("click", (click) => this.closeModal(click, true))
    if (this.listenForEscValue) listenForEsc(this.modal);
  }

  focusModal() {
    super.focusFirstEl(this.modal);
  }

  closeModal(e, checkFormValidity = false) {
    //? Will check form validity so modal doesn't close when required field isn't filled out
    if (checkFormValidity) {
      const form = this.modal.querySelector("form");
      const isValidForm = form.checkValidity();

      if (!isValidForm) return; //? Don't allow modal closing and have HTML5 show input errors
    }
    //? Use a single uncoupled button without modal_component as a whole to close a specific modal with an ID "<button data-modal-selector='modal-component-edit'>"
    if (e.currentTarget.hasAttribute("data-modal-selector")) {
      this.modal = document.querySelector(`#${e.currentTarget.dataset.modalSelector}`);
      closeModal(this.modal, this.modal.querySelector(".modal-card"), this.modal.querySelector(".modal-background"));
      return;
    }
    closeModal(
      this.modal, 
      this.modalCardTarget, 
      this.modalBackgroundTarget, 
      this.destroyOnExitValue,
      this.exitAnimationDurationValue
    );
  }
}

export const listenForEsc = (modal = ".modal-component") => {
  window.addEventListener('keyup', function closeModalOnEsc(e, modal) {
    if (e.key === "Escape") {
      window.removeEventListener("keyup", closeModalOnEsc);
      closeModal(modal);
    } 
  });
};


export const closeModal = (
    modal = ".modal-component",
    modalCard = ".modal-card", 
    modalBackground = ".modal-background",
    modalDestroyOnExit = false
  ) => 
{
  // Check if function call passed in a selector instead of an element (calling this function with a string or a specific nodeElement)
  if (typeof(modal) === "string") modal = document.querySelector(`${modal}.is-active`) 
  if (typeof(modalCard) === "string") modalCard = document.querySelector(`.is-active ${modalCard}`)
  if (typeof(modalBackground) === "string") modalBackground = document.querySelector(`.is-active ${modalBackground}`) 
  modalCard.classList.add("modal-exit-animation");
  //? Grab the modal's css variable for exit animation time, and convert to MS.
  const timeoutValue = (Number(getComputedStyle(modalCard).animationDuration.substring(0, getComputedStyle(modalCard).animationDuration.length - 1)) * 1000) - 100;
  modalBackground.classList.add('delay-fade-out');
  document.querySelector("main").classList.remove("modal-is-open");
  document.body.removeAttribute("modal-open");
  setTimeout(() => {
    modal.classList.remove('is-active');
    modalCard.classList.remove("modal-exit-animation");
    modalBackground.classList.remove('delay-fade-out');
    if (modalDestroyOnExit == true) {
      modal.remove();
    }
  }, timeoutValue);
}