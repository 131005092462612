import ApplicationController from "../../../../../javascript/controllers/application_controller";

export default class extends ApplicationController {
  static targets = [ "radio" ]

  updateSelected(e) {
    document.querySelectorAll(
      `.radio-btn-component:not([data-input-value=${this.radioTarget.value}])`
      ).forEach(
        (radio) => radio.classList.remove("is-selected")
      );
    this.element.classList.add("is-selected");
  }
}