import ApplicationController from "../../../javascript/controllers/application_controller";

export default class extends ApplicationController {
  static targets = [ 
    "accordion", 
    "toggleBtn" 
  ]
  static values = { 
    isOpen: Boolean,
    reflexMethod: String,
    additionalPx: Number
  }  
  
  initialize() {
    if (this.isOpenValue) this.setAccordionHeight();
  }

  toggleOpen() {
    this.isOpenValue = !this.isOpenValue;
    if (this.accordionTarget.style.maxHeight) {
      this.accordionTarget.style.maxHeight = null;
      setTimeout(() => {
        this.element.classList.remove("is-open");
      }, 250)
    } else {
      this.element.classList.add("is-open");
      this.setAccordionHeight();
    }
    if (this.hasReflexMethodValue) this.stimulate(`${this.reflexMethodValue}`, this.isOpenValue);
    this.updateAccessibility();
  }
  
  setAccordionHeight() {
    setAccordionHeight(this.accordionTarget, this.additionalPxValue);
  }

  updateAccessibility() {
    this.toggleBtnTarget.setAttribute("aria-expanded", this.isOpenValue);
    this.accordionTarget.setAttribute("aria-hidden", !this.isOpenValue);
  }
}

export const setAccordionHeight = (accordion, pxValue = 3) => {
  accordion.style.maxHeight = `${accordion.scrollHeight + pxValue}px`;
}