import ApplicationController from "../../../../../javascript/controllers/application_controller";
import Cleave from "cleave.js";

export default class extends ApplicationController {
  connect() {
    new Cleave(this.element, {
      delimiter: '-',
      blocks: [3, 3, 4],
      numerical: true,
      numericOnly: true,
    });
    
    this.setCustomValidityMsg();
  }

  setCustomValidityMsg() {
    const input = this.element;

    input.addEventListener('invalid', function (event) {
      if (event.target.validity.patternMismatch) {
        event.target.setCustomValidity("Please add a 10 digit US phone number");
      }
    })

    input.addEventListener('change', function (event) {
      event.target.setCustomValidity('');
    })
  }
}