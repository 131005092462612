import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  initialize() {
    if (localStorage["dontShowLargeFileModal"]) {
      document.querySelector("#modal-component-large-file-uploaded").remove();
    }
  }

  toggleCheckbox(e) {
    const dontShowAgainCheckbox = e.currentTarget;
    const isChecked = dontShowAgainCheckbox.checked;

    if (isChecked) {
      localStorage.setItem("dontShowLargeFileModal", true);
    } else {
      localStorage.removeItem("dontShowLargeFileModal");
    }
  }
}