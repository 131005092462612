import ApplicationController from "../../../javascript/controllers/application_controller";

export default class extends ApplicationController {
  static values = { selector: String, stepNumber: Number, position: String, isFirst: Boolean }

  initialize() {
    this.componentElement = document.querySelector('#feature-discovery-component');
    this.setStorage = this.componentElement.dataset.setStorage == "true"
    this.storageName = this.componentElement.dataset.storageName;

    if (localStorage.getItem(this.storageName)) return; // Don't render feature discovery since storage is there
  
    if (this.isFirstValue) super.removeVanish(this.element); // Remove vanish on first slide
    
    super.removeVanish(this.componentElement.querySelector(".feature-discovery-background"))

    this.containerId = `${this.element.id}-container`;
    this.targetElement = document.querySelector(`${this.selectorValue}`);
    this.containerElement =  document.createElement("div");
    this.containerElement.id = this.containerId;

    this.targetElement.classList.add("is-highlighted");
    this.targetElement.insertAdjacentElement(this.positionValue, this.containerElement);
    document.getElementById(this.containerId).appendChild(this.element);
  }

  next() {
    this.targetElement.classList.remove("is-highlighted");
    if (this.stepNumberValue == 0) {
      this.componentElement.remove();
      this.containerElement.remove();
      if (this.setStorage) return localStorage.setItem(`${this.storageName}`, true);
    }
    super.addVanish(this.element);
    this.stepNumber = this.stepNumberValue - 1;
    this.nextSlide = document.querySelector(`.feature-discovery-slide-container[data-feature-discovery--feature-discovery-step-number-value='${this.stepNumber}']`);
    super.removeVanish(this.nextSlide);
    this.nextSlide.scrollIntoView({ behavior: "smooth", block: "center"});
  }
}