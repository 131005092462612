// Allows dropdowns to be opened and dismissed
import ApplicationController from "../../../javascript/controllers/application_controller";

export default class extends ApplicationController {
  static targets = ["dropdownBtn", "dropdown", "dropdownBody", "dropdownMenu"];
  static values = {addFocusToParent: Boolean}

  toggleDisplay() {
    this.element.classList.contains("is-open") ? this.animateHide() : this.show();
    
    
    if (!this.data.has("has-dismiss-click-listener"))
    this.listenForDismissClick(this.dropdownMenuTarget);
}

show() {
    if (this.addFocusToParentValue) this.element.parentElement.classList.add("has-focus");
    this.dropdownTarget.classList.add("is-open");
  }

  hide() {
    this.dropdownTarget.classList.remove("is-open");
    this.cleanUpClasses();
  }
  
  cleanUpClasses() {
    this.dropdownBodyTarget.classList.remove("animate-none", "vanish");
    this.dropdownMenuTarget.classList.remove("animate-out");
    this.element?.querySelector(".is-focused")?.classList?.remove("is-focused");
  }

  listenForDismissClick(menu) {
    this.data.set("has-dismiss-click-listener", true);
    document.addEventListener("click", (e) => {
      if (!this.element.contains(e.target)) this.animateHide(menu);
    });
  }

  animateHide() {
    if (this.addFocusToParentValue) this.element.parentElement.classList.remove("has-focus");
    this.dropdownMenuTarget.classList.add("animate-out");
    setTimeout(() => {
      this.hide();
    }, 500);
  }

  disconnect() {
    if (this.hasDropdownBodyTarget) this.hide();
  }
}
